import * as React from "react"
import { navigate } from "gatsby"

const isBrowser = typeof window !== "undefined"

const IndexPage = () => {
  if (isBrowser) {
    navigate(`/login`,  { replace: true });
  }
  return null
}

export default IndexPage
